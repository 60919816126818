<template>
  <main class="mt-16 md:mt-6 xl:mt-16 w-full">
    <section
      id="Featured-artworks"
      class="max-w-screen-md px-6 lg:max-w-screen-lg xl:max-w-screen-xl xl:px-12 2xl:max-w-screen-2xl 2xl:px-24 mx-auto"
    >
      <form
        action="#"
        class="px-4 lg:px-10 py-6 lg:py-14 border border-grey-100 w-full max-w-lg mx-auto"
      >
        <p class="text-grey-900 text-2xl font-bold text-center">Login</p>

        <div class="mt-12">
          <label for="email">
            <span class="sr-only">email</span>
            <input
              v-model="form.email"
              id="email"
              type="email"
              class="mt-10 border-b border-grey-500 w-full pb-3 focus:border-primary-black focus:outline-none"
              placeholder="Email"
            />
          </label>

          <label for="password">
            <span class="sr-only">password</span>
            <input
              v-model="form.password"
              id="password"
              type="password"
              class="mt-10 border-b border-grey-500 w-full pb-3 focus:border-primary-black focus:outline-none"
              placeholder="Password"
            />
          </label>
        </div>

        <button
          @click.prevent="Login"
          type="submit"
          class="mt-12 w-full bg-primary-black block uppercase text-surface-white py-3 rounded"
        >
          Login
        </button>
      </form>
    </section>
  </main>
</template>

<script>
export default {
  created() {
    this.SetRules();
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      rules: null,
      errors: [],
      loding: false,
    };
  },
  methods: {
    Login() {
      this.loding = true;
      this.$http
        .Login(this.form)
        .then((res) => {
          this.loding = false;
          let data = res.data;
          if (data.statusCode === undefined || data.statusCode !== 0) {
            this.$globalAlert.notify(
              "تعذر الاتصال بالخادم، الرجاء المحاولة لاحقا",
              "warning"
            );
            this.$authenticatedUser.userId = 0;
            this.$authenticatedUser.fullName = "";
            this.$authenticatedUser.userName = "";
            this.$authenticatedUser.address = "";
            this.$authenticatedUser.city = "";
            this.$authenticatedUser.country = "";
            this.$authenticatedUser.phoneNo = "";

            return;
          }
        
          this.$authenticatedUser.userId = data.result.userId;
          this.$authenticatedUser.fullName = data.result.fullName;
          this.$authenticatedUser.userName = data.result.userName;
          this.$authenticatedUser.address = data.result.address;
          this.$authenticatedUser.city =data.result.city;
          this.$authenticatedUser.country =data.result.country;
          this.$authenticatedUser.phoneNo = data.result.phoneNo;

          this.$router.replace("/");
        })
        .catch((err) => {
          this.loding = false;
          if (
            err.response.data.statusCode !== null &&
            err.response.data.statusCode !== undefined
          )
            this.$globalAlert.alert(
              err.response.data.result +
                " [" +
                err.response.data.statusCode +
                "] ",
              "error"
            );
          else
            this.$globalAlert.alert(
              "تعذر الاتصال بالخادم، الرجاء المحاولة لاحقا",
              "error"
            );
          this.$authenticatedUser.userId = 0;
          this.$authenticatedUser.fullName = "";
          this.$authenticatedUser.userName = "";
          this.$authenticatedUser.address = "";
          this.$authenticatedUser.city = "";
          this.$authenticatedUser.country = "";
          this.$authenticatedUser.phoneNo = "";
 
        });
    },

    SetRules() {
      this.rules = {};
    },
  },
};
</script>

<style>
</style>